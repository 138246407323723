// @ts-ignore
import { ObjectWithDomElementsT, queryAllWhoHasAttribute } from './utils/dom';

class CheckerHome {
    domElements: ObjectWithDomElementsT;

    constructor(
        domElementsDataAttributeName: string,
    ) {
        this.domElements = queryAllWhoHasAttribute(domElementsDataAttributeName);

        this.domElements?.form.addEventListener('submit', this.handleSubmit.bind(this));
    }

    async handleSubmit() {
        const domAction = this.domElements?.action as HTMLButtonElement;

        domAction?.classList.add('button_loader');
    }
}

// eslint-disable-next-line no-new
new CheckerHome('data-js-checker-home');

export {};
